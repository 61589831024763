<template>
    <v-container class="bg-surface-variant">
        <v-row no-gutters style="flex-wrap: nowrap;">
            <v-col cols="12" @click="goToIndex">
                <!-- <v-img :src="require('../../assets/logo_vue.svg')" class="my-3" contain height="200" /> -->
                <v-img :src="require('../../assets/logo.png')" class="my-3" contain height="200px" />
            </v-col>
        </v-row>
        <div class="d-flex flex-column mb-6 bg-surface-variant">
            <v-row no-gutters style="flex-wrap: nowrap;">
                <v-col>
                    <v-card elevation="2" outlined shaped>
                        <v-row justify="center">
                            <v-card-title class="text-h5">LOGIN</v-card-title>
                        </v-row>
                        <validation-observer ref="observer" v-slot="{ invalid }">
                            <v-form ref="form">
                                <v-row no-gutters>
                                    <v-col cols="3" xs="0" sm="0" md="3" class="flex-grow-0 flex-shrink-0">
                                    </v-col>
                                    <v-col cols="6" xs="8" sm="8" md="6" class="flex-grow-0 flex-shrink-0">
                                        <validation-provider v-slot="{ errors }" name="email" rules="required|email">
                                            <v-text-field v-model="email" :error-messages="errors" label="E-mail"
                                                required></v-text-field>
                                        </validation-provider>
                                        <validation-provider v-slot="{ errors }" name="password" rules="required|pass">
                                            <v-text-field :type="show4 ? 'text' : 'password'" v-model="password"
                                                :error-messages="errors" label="Password" required></v-text-field>
                                        </validation-provider>
                                        <v-card-actions>
                                            <v-spacer></v-spacer>
                                        </v-card-actions>
                                    </v-col>
                                </v-row>
                                <v-row no-gutters>
                                    <v-col cols="5" xs="0" sm="0" md="5">
                                    </v-col>
                                    <v-col cols="2" xs="2" sm="2" md="2">
                                        <v-row no-gutters justify="center">
                                            <v-btn :loading="loading" :disabled="invalid" color="success"
                                                @click="login">
                                                LOGIN
                                            </v-btn>
                                        </v-row>
                                    </v-col>
                                </v-row>
                                <br>
                                <v-row no-gutters style="flex-wrap: nowrap;">
                                    <v-col cols="1" md="1" lg="5">
                                    </v-col>
                                    <v-col cols="10" xs="6" sm="6" md="2">
                                        <v-row no-gutters justify="center">
                                            <p>¿No tienes cuenta?</p>
                                        </v-row>

                                    </v-col>
                                </v-row>
                                <v-row no-gutters style="flex-wrap: nowrap;">
                                    <v-col cols="1" md="1" lg="5">
                                    </v-col>
                                    <v-col cols="10" xs="6" sm="6" md="2">
                                        <!-- <v-row no-gutters justify="center">
                                            <router-link to="/register">Regístrate</router-link>
                                        </v-row> -->
                                        <v-row no-gutters justify="center">
                                            <router-link to="/form_new_user">Regístrate</router-link>
                                        </v-row>
                                    </v-col>
                                </v-row>
                                <br>
                            </v-form>
                        </validation-observer>
                    </v-card>
                </v-col>
            </v-row>
            <v-row v-show="isShow" no-gutters style="flex-wrap: nowrap;">
                <v-col cols="1" xs="1" sm="1" md="4"></v-col>
                <v-col cols="10" xs="8" sm="8" md="4">
                    <v-alert prominent :type="types">
                        <v-row>
                            <v-col class="grow">
                                {{ msgError }}
                            </v-col>
                            <v-col class="shrink">
                                <v-btn @click="closeAlert">OK</v-btn>
                            </v-col>
                        </v-row>
                    </v-alert>
                </v-col>
            </v-row>
        </div>
        <div class="w-auto">
            <DialogMessages ref="dialog" @login="receiveEmit" />
        </div>
    </v-container>
</template>

<script>
import User from '../../models/user';
import { required, email, max } from 'vee-validate/dist/rules'
import { extend, ValidationObserver, ValidationProvider, setInteractionMode } from 'vee-validate'
import DialogMessages from '@/components/utils/DialogMessagesComponent.vue'


setInteractionMode('eager')

extend('required', {
    ...required,
    message: '{_field_} no puede estar vacio.',
})

extend('max', {
    ...max,
    message: '{_field_} no puede ser mayor de {length} caracteres,',
})

extend('email', {
    ...email,
    message: 'Email debe ser valido.',
})

extend("pass", {
    params: ["otherValue"],
    validate: (value) => {
        if (
            value === null ||
            value.length < 6 ||
            !/^(?=.*[0-9])(?=.*[a-z])(?=.*[A-Z])(?=.*[#?!@$%^&*-]).+$/.test(value)
            // !/^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*?&])[A-Za-z\d@$!%*?&].+$/.test(value)
        ) {
            return false;
        }
        return true;
    },
    message:
        "La contraseña no cumple los requisitos. \nDebe contener una mayuscula.\t Debe contener una minuscula.\nDebe contener un caracter especial."
});

export default {
    name: 'LoginComponent',
    components: {
        ValidationProvider,
        ValidationObserver,
        DialogMessages
    },

    data() {
        return {
            user: new User('', ''),
            loader: null,
            loading: false,
            message: '',
            error: '',
            email: '',
            show4: false,
            password: '',
            isShow: false,
            msgError: '',
            types: "success",
            success: false,
            submitStatus: null,
            successful: false
        };
    },
    computed: {
        loggedIn() {
            return this.$store.state.auth.status.loggedIn;
        }
    },
    created() {
        if (this.loggedIn) {
            if (this.$store.state.auth.user.roles[0] !== 'ADMIN') {
                this.$router.push('/home');
            } else {
                this.$router.push('/admin_home');
            }
        }
    },
    methods: {
        login() {
            this.$refs.observer.validate().then(success => {
                if (!success) {
                    return;
                }
                this.loader = 'loading'
                this.loading = true
                // Wait until the models are updated in the UI
                this.$nextTick(() => {
                    try {
                        this.user.email = this.email
                        this.user.password = this.password
                        this.$store.dispatch('auth/login', this.user).then(
                            () => {
                                setTimeout(() => (this.responseLogin('success', 'Login correcto!!!')), 2000)
                            },
                            error => {
                                this.message =
                                    (error.response && error.response.data && error.response.data.message) ||
                                    error.message ||
                                    error.toString();
                                setTimeout(() => (this.responseLogin('error', error.response.data.message)), 2000)
                            })
                    } catch (error) {
                        setTimeout(() => (this.responseLogin('error', error.response)), 2000)
                    }
                });
            });
        },
        goToIndex() {
            this.$router.push('/');
        },
        responseLogin(response, msg) {
            this.$refs.dialog.openModal()
            this.$refs.dialog.component = 'LOGIN'
            this.$refs.dialog.tituloDialog = 'LOGIN'
            this.$refs.dialog.msgError = msg
            this.$refs.dialog.isShow = true
            if (response === 'success') {
                this.$refs.dialog.types = 'success'
                this.successful = true
            } else {
                this.$refs.dialog.types = 'error'
                this.successful = false
            }
        },
        closeAlert() {
            this.loading = false
            if (this.successful) {
                this.isShow = false
                if (this.$store.state.auth.user.roles[0] === 'ADMIN') {
                    this.$router.push('/admin_home');
                } else {
                    this.$router.push('/home');
                }
            } else {
                this.isShow = false
            }
        },
        receiveEmit() {
            console.log()
            this.closeAlert()
        }
    }
}
</script>

<style lang="scss" scoped>

.v-card {
border-radius: 6px;
// margin: 24px;
// overflow-y: auto;
// pointer-events: auto;
// transition: 0.3s cubic-bezier(0.25, 0.8, 0.25, 1);
// // width: 100%;
// z-index: inherit;
// box-shadow: 6 6 60px 30px #845AFF;
}
</style>