import api from './api';
import config from './direction';

const API_URL = config.URL;

class UserService {
  setVerify(verify) {
    return api.get('verify?code=' + verify);
  }
  // User
  register(user) {
    return api
      .post(API_URL + 'signup', user)
      .then(response => {
        return response;
      }).catch(error => { return error });
  }

  sendForm(user) {
    return api
      .post(API_URL + 'formSignup', user)
      .then(response => {
        return response;
      }).catch(error => { return error });
  }

  getHomeInfo() {
    return api.get('getHomeInfo');
  }

  getUserProfile(email) {
    return api.get('profile?email=' + email);
  }

  saveUser(user) {
    return api
      .post('saveUser', user)
      .then(response => {
        if (response.data.accessToken) {
          localStorage.setItem('user', JSON.stringify(response.data));
        }
        return response.data;
      });
  }

  changePass(email, old_password, new_password) {
    return api
      .post('changePassword', { email: email, old_password: old_password, new_password: new_password })
      .then(response => {
        if (response.data.accessToken) {
          localStorage.setItem('user', JSON.stringify(response.data));
        }
        return response.data;
      });
  }

  // Pays
  getCart() {
    return api.get('getCart');
  }

  getPays() {
    return api
      .get(API_URL + 'pays')
      .then(response => {
        return response.data;
      });
  }

  getTypes() {
    return api.get('getTypes');
  }

  getType(idType) {
    return api.get('getType?idTipoSuscripcion=' + idType);
  }

  getTransferCoinPayment(idSuscripcion) {
    return api
      .get(API_URL + 'getTransferCoinPayment?idSuscripcion=' + idSuscripcion)
      .then(response => {
        return response.data;
      });
  }

  cryptoPay(idSuscripcion) {
    return api
      .get(API_URL + 'cryptoPay?idSuscripcion=' + idSuscripcion)
      .then(response => {
        return response.data;
      });
  }

  finalizeOrder(idSuscripcion, orderId) {
    return api
      .get(API_URL + 'finalizeOrder?idSuscripcion=' + idSuscripcion + '&orderId=' + orderId)
      .then(response => {
        return response.data;
      });
  }

  chekPeriodBot(idSuscripcion) {
    return api.get('chekPeriodBot?idSuscripcion=' + idSuscripcion);
  }

  chekPeriodSus(email) {
    return api.get('chekPeriodSus?email=' + email);
  }

  getSuccessPay(orderId) {
    return api
      .get(API_URL + 'successPay?orderId=' + orderId)
      .then(response => {
        return response.data;
      });
  }

  getAmazonButton() {
    return api
      .post(API_URL + 'getAmazonButton')
      .then(response => {
        return response.data;
      });
  }

  getCheckoutSession(sessionId) {
    return api
      .get(API_URL + 'getCheckoutSession?sessionId=' + sessionId)
      .then(response => {
        return response.data;
      });
  }

  postUpdateCheckoutSession(sessionId, idSuscripcion, cantidad) {
    return api
      .post(API_URL + 'updateCheckoutSession?sessionId=' + sessionId + '&idTipoSuscripcion=' + idSuscripcion + '&cantidad=' + cantidad)
      .then(response => {
        return response.data;
      });
  }

  postCompleteCheckoutSession(sessionId, idSuscripcion, cantidad) {
    return api
      .post(API_URL + 'completeCheckoutSession?sessionId=' + sessionId + '&idTipoSuscripcion=' + idSuscripcion + '&cantidad=' + cantidad)
      .then(response => {
        return response.data;
      });
  }

  postFinalizeCheckoutSession(sessionId, idSuscripcion, cantidad) {
    return api
      .post(API_URL + 'finalizeCheckoutSession?sessionId=' + sessionId + '&idTipoSuscripcion=' + idSuscripcion + '&cantidad=' + cantidad)
      .then(response => {
        return response.data;
      });
  }

  getLinkPay(email, payName) {
    return api
      .get(API_URL + 'getLinkPay', { email: email, payName: payName })
      .then(response => {
        return response.data;
      });
  }

  getSignInAmazon() {
    return api
      .get(API_URL + 'signInAmazon')
      .then(response => {
        return response.data;
      });
  }

  // getLinkPay(email, payName) {
  //   return api
  //     .post(API_URL + 'getLinkPay', { email: email, payName: payName })
  //     .then(response => {
  //       return response.data;
  //     });
  // }

  // getCharge(email) {
  //   return api.get('charge?email=' + email);
  // }

  // getCheckout(user) {
  //   return api
  //     .post(API_URL + 'checkout', user)
  //     .then(response => {
  //       return response.data;
  //     });
  // }

  getCheckout(idCheckout) {
    return api
      .post(API_URL + 'getCheckout?idCheckout=', idCheckout)
      .then(response => {
        return response.data;
      });
  }

  getSession(sessionId) {
    return api.get('getSession?sessionId=' + sessionId);
  }

  postPay(email, payIntent) {
    return api
      .post(API_URL + 'postPay', { email: email, payIntent: payIntent })
      .then(response => {
        return response.data;
      });
  }

  // Client
  getClients(email) {
    return api
      .get(API_URL + 'getClients?email=' + email);
  }

  getClient(idClient) {
    return api
      .get(API_URL + 'getClient?idClient=' + idClient);
  }

  postClient(client) {
    return api
      .post(API_URL + 'saveClient', client)
      .then(response => {
        return response.data;
      });
  }

  getConnect(idClient){
    return api.get(API_URL + 'connect?idClient=' + idClient)
  }

  // connect(email, idClient) {
  //   return api
  //     .post(API_URL + 'connect', { email: email, idClient: idClient })
  //     .then(response => {
  //       return response.data;
  //     });
  // }

  getExchanges() {
    return api.get(API_URL + 'getExchanges');
  }

  // Wallets
  getWallets(idClient) {
    return api.get(API_URL + 'getWallets?idClient=' + idClient);
  }

  // Bots
  postSaveBot(suscripcion) {
    return api
      .post('saveBot', suscripcion)
      .then(response => {
        return response.data;
      });
  }

  postUpdateBot(bot) {
    return api
      .post('updateBot', bot)
      .then(response => {
        return response.data;
      });
  }

  getBot(idBot) {
    return api.get(API_URL + 'getBot?idBot=' + idBot);
  }

  getSuscripcion(idSuscripcion) {
    return api.get(API_URL + 'getSuscripcion?idSuscripcion=' + idSuscripcion);
  }

  cancelarSuscripcion(idSuscripcion) {
    return api.get(API_URL + 'cancelarSuscripcion?idSuscripcion=' + idSuscripcion);
  }

  getTotalSuscripciones() {
    return api.get(API_URL + 'getTotalSuscripciones');
  }

  getVerifyPayment(transactionId, accion, idSuscripcion, tipoSuscripcion) {
    return api.get(API_URL + 'verifyPayment?transactionId=' + transactionId + '&accion=' + accion + '&suscripcion=' + idSuscripcion + '&idTipoSuscripcion=' + tipoSuscripcion);
  }

  deleteBot(bot) {
    return api
      .post('deleteBot', bot)
      .then(response => {
        return response.data;
      });
  }

  getListBots(email) {
    return api.get(API_URL + 'getListBots?email=' + email);
  }

  getRiesgos() {
    return api.get(API_URL + 'getRiesgos');
  }

  getListByRiesgo(exchange, riesgo) {
    return api.get(API_URL + 'getListByRiesgo?exchange=' + exchange + '&riesgo=' + riesgo);
  }

  postStatusBot(bot) {
    return api
      .post('initBot', bot)
      .then(response => {
        return response.data;
      });
  }

  pauseBot(bot) {
    return api
      .post('pauseBot', bot)
      .then(response => {
        return response.data;
      });
  }

  getStatusBot(id) {
    return api.get('getStatusBot?bot=' + id);
  }

  getOrdersBot(id) {
    return api.get('getOrders?bot=' + id);
  }

  getTradesBot(id) {
    return api.get('getTrades?bot=' + id);
  }

  getChartBot(idBot) {
    return api.get('getChartBot?bot=' + idBot);
  }

  isAccount(email) {
    return api.get('isAccount?email=' + email)
  }

  // TICKETS
  getTickets(email) {
    return api.get('getTickets?email=' + email)
  }

  postSaveTicket(ticket) {
    return api
      .post('saveTicket', ticket)
      .then(response => {
        return response.data;
      });
  }

  getTicket(idTicket) {
    return api.get('getTicket?idTicket=' + idTicket)
  }

  // ADMIN
  getUsers() {
    return api.get('getUsers')
  }

  getAdminUser(idUser) {
    return api.get('getUser?idUser=' + idUser)
  }

  saveAdminUser(user) {
    return api
      .post('saveUserAdmin', user)
      .then(response => {
        return response.data;
      });
  }

  registerAdmin(user) {
    return api
      .post(API_URL + 'signupAdmin', user)
      .then(response => {
        return response;
      }).catch(error => { return error });
  }

  getBots() {
    return api.get(API_URL + 'getBots');
  }

  getAdminBot(idBot) {
    return api.get(API_URL + 'getAdminBot?idBot' + idBot);
  }

  getAdminTradesBot(id) {
    return api.get('getAdminTrades?bot=' + id);
  }

  getAdminTrade(idCompra, idVenta) {
    return api.get('getAdminTrade?idCompra=' + idCompra + '&idVenta=' + idVenta);
  }

  getAdminChartBot(idBot) {
    return api.get('getAdminChartBot?bot=' + idBot);
  }

  getAdminSubs() {
    return api.get('getAdminSubs')
  }

  getAdminTipoSuscripcion(idSuscripcion) {
    return api.get('getAdminSub?idSuscripcion=' + idSuscripcion)
  }

  saveAdminTipoSuscripcion(type) {
    return api
      .post('saveAdminTipoSuscripcion', type)
      .then(response => {
        return response.data;
      });
  }

  getAdminComerciales() {
    return api.get('getAdminComerciales')
  }

  saveComercialAdmin(comercial) {
    return api
      .post(API_URL + 'saveComercialAdmin', comercial)
      .then(response => {
        return response;
      }).catch(error => { return error });
  }

  getAdminComercial(idComercial) {
    return api.get(API_URL + 'getAdminComercial?idComercial=' + idComercial);
  }

  getAdminPays() {
    return api.get(API_URL + 'getAdminPagos')
  }

  savePagoAdmin(pagoRequest) {
    return api
      .post('savePagoAdmin',  pagoRequest )
      .then(response => {
        // if (response.data.accessToken) {
        //   localStorage.setItem('user', JSON.stringify(response.data));
        // }
        return response;
      });
  }

  getAdminTickets() {
    return api.get('getAdminTickets')
  }

  // getUserProfile(email) {
  //   return axios.get(API_URL + 'profile?email='+  email, { headers: authHeader() });
  // }

  // saveUser(user) {
  //   return axios
  //     .post(API_URL + 'saveUser', user, { headers: authHeader() })
  //     .then(response => {
  //       if (response.data.accessToken) {
  //         localStorage.setItem('user', JSON.stringify(response.data));
  //       }

  //       return response.data;
  //     });
  // }

  // getBotConfig(email) {
  //   return axios
  //     .get(API_URL + 'getConfig?email=' + email, { headers: authHeader() });
  // }

  // postBotConfig(client) {
  //   return axios
  //     .post(API_URL + 'saveConfig', client, { headers: authHeader() })
  //     .then(response => {
  //       return response.data;
  //     });
  // }

  // postSaveBot(bot) {
  //   return axios
  //     .post(API_URL + 'saveBot', bot, { headers: authHeader() })
  //     .then(response => {
  //       return response.data;
  //     });
  // }

  // getListBots(email) {
  //   return axios.get(API_URL + 'getListBots?email=' + email, { headers: authHeader() });
  // }

}

export default new UserService();
