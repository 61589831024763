<template>
    <v-container class="bg-surface-variant">
        <div class="d-flex flex-column mb-6 bg-surface-variant">
            <v-row no-gutters style="flex-wrap: nowrap;">
                <v-col>
                    <v-card elevation="2" outlined shaped>
                        <v-row justify="center">
                            <v-card-title class="text-h5">{{ title }}</v-card-title>
                        </v-row>
                        <v-divider class="mx-4"></v-divider>
                        <v-row no-gutters>
                            <v-col cols="2" xs="0" sm="0" md="2" class="flex-grow-0 flex-shrink-0"></v-col>
                            <v-col cols="8" xs="0" sm="0" md="8" class="flex-grow-0 flex-shrink-0">
                                <v-row no-gutters style="flex-wrap: nowrap;">
                                    <v-col cols="2" xs="0" sm="0" md="4" class="flex-grow-0 flex-shrink-0"></v-col>
                                    <v-col cols="8" xs="0" sm="0" md="4" class="flex-grow-0 flex-shrink-0">
                                        <v-select v-model="exchange" :items="itemsExchange" item-text="exchange"
                                            item-value="id" label="Select Exchange" @change="onChange($event)"
                                            persistent-hint return-object single-line></v-select>
                                    </v-col>
                                </v-row>
                            </v-col>
                            <v-row no-gutters style="flex-wrap: nowrap;">
                                <v-col cols="2" xs="0" sm="0" md="2" class="flex-grow-0 flex-shrink-0"></v-col>
                                <v-col cols="8" xs="0" sm="0" md="8" class="flex-grow-0 flex-shrink-0">
                                    <v-data-table :headers="headers" :items="items" :search="search"></v-data-table>
                                </v-col>
                            </v-row>
                        </v-row>
                        <br>
                        <v-divider class="mx-4" horizontal></v-divider>
                        <br>
                    </v-card>
                </v-col>
            </v-row>
        </div>
        <div class="w-auto">
            <DialogMessages ref="dialog" />
        </div>
    </v-container>
</template>

<script>
import EventBus from "../../common/EventBus";
import UserService from '../../services/user.service';
import DialogMessages from '@/components/utils/DialogMessagesComponent.vue'

export default {
    name: 'WalletComponent',
    components: {
        DialogMessages
    },
    data() {
        return {
            idBot: '',
            title: "WALLETS",
            // Table
            headers: [
                { text: "Divisa", value: "currency", align: "center" },
                { text: "Disponible", value: "available", align: "center" },
                { text: "Congelado", value: "frozen", align: "center" },
                { text: "Total", value: "total", align: "center" },
                { text: "Retirando", value: "withdrawing", align: "center" }
            ],
            items: [],
            itemsExchange: [],
            exchange: '',
            right: null,
            search: '',
            start: false,
        };
    },
    computed: {},
    created() {
    },
    mounted() {
        UserService.getClients(this.$store.state.auth.user.email).then(
            response => {
                if (response.data !== '') {
                    // console.log(response.data)
                    // this.desserts = response.data;
                    for (let i = 0; i < response.data.length; i++) {
                        let aux = { 'id': response.data[i]['id'], 'exchange': response.data[i]['exchange']['exchange'] }
                        this.itemsExchange.push(aux);
                    }
                }
            },
            error => {
                this.content =
                    (error.response &&
                        error.response.data &&
                        error.response.data.message) ||
                    error.message ||
                    error.toString();

                if (error.response && error.response.status === 403) {
                    EventBus.dispatch("logout");
                }
            }
        );
    },
    methods: {
        onChange(exchange) {
            this.items = []
            // console.log(exchange.id)
            UserService.getWallets(exchange.id).then(
                response => {
                    this.loadData(response.data);
                },
                error => {
                    this.content =
                        (error.response && error.response.data && error.response.data.message) ||
                        error.message ||
                        error.toString();
                    setTimeout(() => (this.responseWallet('error', error.response.data)), 2000)
                }
            );
        },
        loadData(data) {
            this.items = data;
        },
        responseWallet(response, msg) {
            this.$refs.dialog.openModal()
            this.$refs.dialog.component = 'WALLET'
            this.$refs.dialog.tituloDialog = 'WALLET'
            this.$refs.dialog.msgError = msg
            this.$refs.dialog.isShow = true
            if (response === 'success') {
                this.$refs.dialog.types = 'success'
                this.successful = true
            } else {
                this.$refs.dialog.types = 'error'
                this.successful = false
            }
        }
    }
}
</script>