<template>
    <v-container class="bg-surface-variant">
        <div class="d-flex flex-column mb-6 bg-surface-variant">
            <v-row no-gutters style="flex-wrap: nowrap;">
                <v-col>
                    <v-card elevation="2" outlined shaped>
                        <v-row justify="center">
                            <v-card-title class="text-h5">{{ title }}</v-card-title>
                        </v-row>
                        <v-row no-gutters>
                            <v-card class="pa-2" elevation="2" outlined shaped>
                                <br>
                                <v-img :src="require('../../assets/wallet_tron.png')" class="my-3" contain
                                    height="200" />
                                <br>
                                <v-row no-gutters>
                                    <v-col cols="1" xs="0" sm="0" md="2" class="flex-grow-0 flex-shrink-0"></v-col>
                                    <v-col cols="10" xs="8" sm="8" md="8" class="flex-grow-0 flex-shrink-0">
                                        <v-card class="pa-2">
                                            <div class="checkout">
                                                <h1>Pago con USDT (TRC-20)</h1>
                                                <br>
                                                <div class="instructions">
                                                    <h2>Instrucciones para realizar el pago</h2>
                                                    <br>
                                                    <ol>
                                                        <li>Inicia sesión en tu cuenta de <strong>KuCoin</strong> o
                                                            <strong>Binance</strong>.
                                                        </li>
                                                        <li>Navega a la sección de "Cartera" o "Wallet".</li>
                                                        <li>Selecciona <strong>USDT</strong> y elige "Retirar" o
                                                            "Withdraw".
                                                        </li>
                                                        <li>Ingresa la dirección TRON proporcionada a continuación:</li>
                                                        <!-- <p><strong>Tu dirección TRON: {{ tronAddress }}</strong></p> -->
                                                        <p
                                                            style="line-height: 115%;margin-bottom: 0.25cm;background: transparent;">
                                                            <strong style="font-weight: bold;">WALLET: </strong>
                                                            <strong v-on:focus="$event.target.select()" ref="myWallet"
                                                                style="font-weight: bold;">{{
                                                                    tronAddress }}</strong>
                                                            <v-btn class="mx-2" fab dark small color="secondary"
                                                                @click="copyClipboard">
                                                                <v-icon dark>
                                                                    mdi-clipboard-outline
                                                                </v-icon>
                                                            </v-btn>
                                                        </p>
                                                        <li>Ingresa la cantidad que deseas enviar y confirma la
                                                            transacción.
                                                        </li>
                                                        <br>
                                                        <p
                                                            style="line-height: 115%;margin-bottom: 0.25cm;background: transparent;">
                                                            <strong style="font-weight: bold;">PRECIO: {{ this.total }}
                                                                $</strong>
                                                        </p>
                                                        <br>
                                                        <li>Una vez completada, busca el <strong>transaction ID</strong>
                                                            en
                                                            el historial de
                                                            transacciones de
                                                            KuCoin o Binance.</li>
                                                        <li>Ingresa el <strong>transaction ID</strong> a continuación
                                                            para
                                                            verificar el
                                                            estado del pago.</li>
                                                    </ol>
                                                </div>
                                                <br>
                                                <div class="form">
                                                    <label for="transactionId">Transaction ID:</label>
                                                    <input v-model="transactionId"
                                                        placeholder="Introduce el Transaction ID" />
                                                    <!-- <button :loading="loading" @click="checkPaymentStatus">Verificar
                                                        Pago</button> -->
                                                    <v-btn :loading="loading" color="success"
                                                        @click="checkPaymentStatus">
                                                        Verificar
                                                        Pago
                                                    </v-btn>
                                                </div>
                                                <br>
                                            <!--    <div v-if="paymentStatus">
                                                    <h3>Estado del Pago: {{ paymentStatus }}</h3>
                                                    <p>
                                                         <a :href="'https://tronscan.org/#/transaction/' + transactionId"
                                                            target="_blank">
                                                            Verificar en TronScan
                                                        </a> 
                                                    </p>
                                                </div>
                                                <div v-if="errorMessage" class="error">
                                                    <p>Error: {{ errorMessage }}</p>
                                                </div>-->
                                            </div>
                                            <br>
                                        </v-card>
                                    </v-col>
                                </v-row>


                            </v-card>

                        </v-row>
                    </v-card>
                </v-col>
            </v-row>
        </div>
        <div class="w-auto">
            <DialogModal ref="modalDialog" />
        </div>
        <div class="w-auto" v-show="isOpenDialog">
            <DialogMessages ref="dialogMessages" @pago="closeAlert" />
        </div>
    </v-container>
</template>

<script>
import UserService from '../../services/user.service';
import EventBus from "../../common/EventBus";
import TipoSuscripcion from '../../models/tipoSuscripcion';
import * as Address from '@/services/direction.js'
import DialogModal from '@/components/utils/DialogClipboardComponent.vue'
import DialogMessages from '@/components/utils/DialogMessagesComponent.vue'

export default {
    name: 'TronPaymentComponent',
    components: {
        DialogModal,
        DialogMessages
    },
    data() {
        return {
            tipoSuscripcion: new TipoSuscripcion('', ''),
            title: 'Pagar Suscripción.',
            transactionId: '',
            tronAddress: '', // Aquí puedes poner tu dirección TRON
            paymentStatus: '',
            totalSuscripciones: '',
            errorMessage: '',
            precio: '',
            total: '',
            // PARAMS
            accion: '',
            idSuscripcion: '',
            idTipoSuscripcion: '',
            loader: null,
            loading: false,
            isOpenDialog: false,
            successful: false
        };
    },
    mounted() {
        this.tronAddress = Address.WALLET
        let values = Object.values(this.$route.query)
        // console.log(values)

        this.accion = values[0]
        this.idSuscripcion = values[1]
        this.idTipoSuscripcion = values[2]

        if (this.accion === 'new') {
            // console.log('new')
            this.totalSuscripciones = 1
        } else {
            // console.log('update')
            if (this.idSuscripcion === 'all') {
                // console.log('all')
                UserService.getTotalSuscripciones().then(
                    response => {
                        this.totalSuscripciones = response.data
                    },
                    error => {
                        this.content =
                            (error.response && error.response.data && error.response.data.message) ||
                            error.message ||
                            error.toString();
                        if (error.response && error.response.status === 403) {
                            EventBus.dispatch("logout");
                        }
                    }
                );
            } else {
                this.totalSuscripciones = 1
            }
        }

        UserService.getType(this.idTipoSuscripcion).then(
            response => {
                this.precio = response.data.precio
            },
            error => {
                this.content =
                    (error.response && error.response.data && error.response.data.message) ||
                    error.message ||
                    error.toString();
                if (error.response && error.response.status === 403) {
                    EventBus.dispatch("logout");
                }
            }
        );

        setTimeout(() => this.loadTotalPrecio(), 500);

    },
    methods: {
        loadTotalPrecio() {
            this.total = this.precio * this.totalSuscripciones
        },
        async checkPaymentStatus() {
            console.log(this.transactionId)
            if (this.transactionId === '') {
                this.errorMessage = 'Por favor, ingresa el Transaction ID';
                setTimeout(() => (this.responsePayment('error', this.errorMessage)), 2000)
                return
            }
            this.loader = 'loading'
            this.loading = true

            try {
                UserService.getVerifyPayment(this.transactionId, this.accion, this.idSuscripcion, this.idTipoSuscripcion).then(
                    response => {
                        console.log(response.data)
                        this.paymentStatus = response.data;
                        this.errorMessage = '';
                        this.loading = false
                        setTimeout(() => (this.responsePayment('success', this.paymentStatus)), 2000)
                    },
                    error => {
                        this.content =
                            (error.response && error.response.data && error.response.data.message) ||
                            error.message ||
                            error.toString();
                        if (error.response && error.response.status === 403) {
                            // EventBus.dispatch("logout");
                            this.loading = false
                            setTimeout(() => (this.responsePayment('error', error.response)), 2000)
                        }
                    }
                );

                // const response = await fetch(`/api/tron/verify/${this.transactionId}`);
                // const result = await response.json();

                // if (response.ok) {
                //     this.paymentStatus = result;
                //     this.errorMessage = '';
                // } else {
                //     this.errorMessage = result.message || 'No se encontró el pago';
                //     this.paymentStatus = '';
                // }
            } catch (error) {
                this.errorMessage = 'Error al verificar el pago: ' + error.message
                this.paymentStatus = ''
                setTimeout(() => (this.responsePayment('error', this.errorMessage)), 2000)
            }

        },
        responsePayment(response, msg) {
            this.$refs.dialogMessages.openModal()
            this.$refs.dialogMessages.component = 'PAGAR SUSCRIPCION'
            this.$refs.dialogMessages.tituloDialog = 'PAGO DE SUSCRIPCION'
            this.$refs.dialogMessages.msgError = msg
            this.$refs.dialogMessages.isShow = true
            if (response === 'success') {
                this.$refs.dialogMessages.types = 'success'
                this.successful = true
            } else {
                this.$refs.dialogMessages.types = 'error'
                this.successful = false
            }
        },
        async copyClipboard() {
            try {
                await navigator.clipboard.writeText(this.tronAddress);
                // this.isShow = true
                this.$refs.modalDialog.openModal()
                this.$refs.modalDialog.msg = 'Se ha copiado la wallet en su portapapeles.'
                this.$refs.modalDialog.type = 'success'
                // alert('Copied');
            } catch ($e) {
                this.$refs.modalDialog.openModal()
                this.$refs.modalDialog.msg = 'Ocurrió un error al copiar la wallet al portapapeles.'
                this.$refs.modalDialog.type = 'error'
            }
        },
        closeAlert() {
            if (this.successful) {
                this.$router.go()
            }
        }
    }
};
</script>

<style scoped>
.checkout {
    max-width: 400px;
    margin: 0 auto;
    text-align: center;
}

.instructions {
    margin-bottom: 20px;
    text-align: left;
}

.form {
    margin-bottom: 20px;
}

input {
    padding: 10px;
    margin-right: 10px;
    width: 300px;
}

button {
    padding: 10px 20px;
    background-color: #3498db;
    color: white;
    border: none;
    cursor: pointer;
}

button:hover {
    background-color: #2980b9;
}

.error {
    color: red;
}
</style>