<template>
  <v-app>

    <!-- <v-app-bar app color="primary" dark :elevation="2"> -->
    <div class="w-auto">

      <!-- <v-app-bar app style="background-color:rgba(6,96,158,255);" dark :elevation="2"> -->
      <v-app-bar app dark :elevation="2">
        <!-- Navigation User -->
        <div v-if="isUser">

          <v-app-bar-nav-icon @click="showNavigation"></v-app-bar-nav-icon>
        </div>
        <div class="d-none d-lg-block">
          <v-app-bar-title class="text-h6 text-lg-h5"> TRADE MASTER BOTS. </v-app-bar-title>
        </div>
        <v-spacer></v-spacer>
        <!-- Navigation Public -->
        <!-- <div class="w-auto" v-if="!currentUser">
          <NavLoginComponent />
        </div> -->
      </v-app-bar>

    </div>

    <v-navigation-drawer v-if="isUser" v-model="drawer" fixed left temporary>
      <!-- expand-on-hover> -->
      <NavigationComponent @logout="receiveEmit" ref="nav"/>
    </v-navigation-drawer>


    <!-- Sizes your content based upon application components -->
    <v-main style="padding: 60px 0px 0px;">
    <!-- <v-main style="padding: 60px 0px 0px; background-color:rgba(0,0,28,255);"> -->

      <!-- Provides the application the proper gutter -->
      <v-container fluid>
        <router-view ></router-view>
      </v-container>
    </v-main>

    <!-- <CookieModal/> -->
    <v-footer class="d-flex flex-column"  dark>
    <!-- <v-footer class="d-flex flex-column" style="background-color:rgba(6,96,158,255);" dark> -->

      <div class="d-flex w-100 align-center px-4" dark>
        <v-card class="flex"  dark>
          <v-card-title>
            Conectate a nuestras redes sociales!
          </v-card-title>
        </v-card>

        <v-spacer></v-spacer>
        <div class="d-flex w-100 align-center px-4" dark>
          <v-btn v-for="icon in icons" :key="icon"  icon @click="toGo(icon)">
          <v-icon size="24px">
            {{ icon }}
          </v-icon>
        </v-btn>
        </div>
       
      </div>
      <div class="px-4 py-2 bg-black text-center w-100">
        {{ new Date().getFullYear() }} — <strong>A.Molina</strong>
      </div>
      <div class="px-4 py-2 bg-black text-center w-100">
        <v-list-item @click="$router.push('/terms').catch(err => { })">Terms</v-list-item>
      </div>
    </v-footer>
  </v-app>
</template>

<script>
import NavigationComponent from './components/utils/NavigationComponent.vue'
// import NavLoginComponent from './components/utils/NavLoginComponent.vue'
import EventBus from "./common/EventBus";
// import CookieModal from "./components/cookies_vue/CookieModal.vue";

export default {
  name: 'App',
  components: {
    NavigationComponent,
    // CookieModal
    // NavLoginComponent
  },
  data() {
    return {
      group: null,
      items: [
        { title: "Login", icon: "mdi-view-dashboard", route: "/" },
        { title: "Photos", icon: "mdi-image" },
        { title: "About", icon: "mdi-help-box" }
      ],
      icons: [
        'fa-brands fa-tiktok',
        'mdi-instagram',
      ],
      right: null,
      drawer: false,
      width: 0,
      login: false,
      // isModalVisible: true
    };
  },
  mounted() {
    this.drawer = false
    EventBus.on("logout", () => {
      this.logOut();
    });

    // console.log(this.drawer)
  },
  created() {

    if (this.$store.state.user === "") {
      this.$store.dispatch("auth/logout");
      this.$router.push("/");
    }

  
  },
  computed: {
    currentUser() {
      return this.$store.state.auth.user;
    },
    isUser() {
      if (this.$store.state.auth.user !== null && this.$store.state.auth.status.loggedIn === true ) {
        // console.log(this.$ref)
        // this.$ref.nav.loadData()
        // console.log(this.$store.state.auth.user.roles[0])
        if (this.$store.state.auth.user.roles[0] !== 'ADMIN' && this.$store.state.auth.user.roles[0] !== null) {
          return this.$store.state.auth.user;
        } else {
          return false;
        }
      } else {
        return false
      }
    }
  },
  methods: {
    receiveEmit() {
      this.logOut()
    },
    showNavigation() {
      this.drawer = !this.drawer
    },
    logOut() {
      this.drawer = false
      this.$store.dispatch("auth/logout");
      // this.$router.push("/");
    },
    // goLogin() {
    //   this.$router.push("/login").catch(err => { err });
    // },
    // // goRegister() {
    // //   this.$router.push("/register").catch(err => {err});
    // // },
    // goRegister() {
    //   this.$router.push("/form_new_user").catch(err => { err });
    // },
    toGo(icon) {
      if (icon === 'mdi-instagram') {
        // console.log(icon)
        window.open('https://www.instagram.com/trademasterbots/', '_blank');
      }

    },
    closeModal() {
      this.isModalVisible = false;
    },
    setCookies(selectedCookies) {
      console.log("Selected:", selectedCookies);
    },
    toggleScroll(payload) {
      document.body.style.overflow = !payload ? 'hidden' : ''
    }
  },
  beforeDestroy() {
    EventBus.remove("logout");
  },

  watch: {
    // group() {
    //   console.log("watch")
    //   this.drawer = null
    // },
  },
}
</script>
