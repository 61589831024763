<template>
    <transition name="modal-fade">
        <div class="modal-backdrop" v-if="showModal">
            <div class="modal" role="dialog" aria-labelledby="modalTitle" aria-describedby="modalDescription">
                <v-banner avatar="https://cdn.vuetifyjs.com/docs/images/logos/v.svg" stacked>
                    <template>
                        <div class="modal-body">
                            <v-dialog v-model="dialog" max-width="290">
                                <v-card>
                                    <v-card-title class="text-h5">
                                        <!-- Eliminar Bot -->
                                        {{ tituloDialog }}
                                    </v-card-title>
                                    <v-card-text>
                                        <!-- ¿ Desea realmente eliminar el bot ? -->
                                        {{ question }}
                                    </v-card-text>
                                    <v-card-actions>
                                        <v-spacer></v-spacer>
                                        <v-btn color="red darken-1" text @click="dialog = false">
                                            Cancelar
                                        </v-btn>
                                        <v-btn color="green darken-1" text @click="actionAceptar">
                                            Aceptar
                                        </v-btn>
                                    </v-card-actions>
                                </v-card>
                            </v-dialog>
                        </div>
                    </template>
                </v-banner>
            </div>
        </div>
    </transition>

</template>

<script>


export default {
    name: 'DialogQuestionComponent',
    data: function () {
        return {
            component: '',
            showModal: true,
            dialog: false,
            question: '',
            tituloDialog: ''
        }
    },
    computed: {

    },
    methods: {
        openModal() {
            this.dialog = true
        },
        actionAceptar(){
            if (this.component === 'DELETE_BOT') {
                this.$emit('deleteBot', 'eliminarBot');
            } else if (this.component === 'DELETE_SUSCRIPCION') {
                this.$emit('deleteSuscripcion', 'cancelarSuscripcion');
            }
            this.dialog = false
        }

    },
    mounted() {

    }
}
</script>